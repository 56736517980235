<template>

  <div :class="'fI login-page  mobile_' + isMobile()">

    <div id="loginWidget">
      <div class="pulsate loginWidgetText">
        <div>~ <translate>PASSWORD RESET</translate> ~</div>
      </div>
    </div>

    <!--LoginWidgets /-->

    <div style="overflow: hidden;">

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <div id='box-container' style="width: 100%; overflow: hidden;">

        <div id="forgotPasswordForm"  v-if="!sentRequest">
          <FormulateForm
            v-model='values'
            :errors='errors'
            @submit="forgotPassword()"
            #default="{ hasErrors }"
          >
            <div v-if='errors.root' class='landingPageInput item-errors alert alert-danger'>
              <translate>Forgot password error:</translate> { errors.root }
            </div>

            <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper form-group">
              <div class="formText" style="margin: auto;">
                <span v-translate>If you have <b>forgotten your password</b>, please enter your email address below and we will send you instructions for setting a new one.</span><br/><br/>
              </div>
              <FormulateInput
                  v-focus
                  autocomplete="new-password"
                  class="landingPageInput"
                  type='email'
                  name='email'
                  validation='email'
                  :placeholder="$gettext('Your email address')"
              />
            </div>
            <div class="logo_spacer_bottom"></div>
            <div class="form-group">
              <button v-if="hasErrors" disabled class="landingPageButton btn btn-primary trans" style="opacity: 0.3; cursor: not-allowed;"><i class="fas fa-recycle fas-left"></i><translate>RESET PASSWORD</translate></button>
              <button v-else class="landingPageButton btn btn-danger" ><i class="fas fa-recycle fas-left"></i><translate>RESET PASSWORD</translate></button>
              <br/>
              <router-link class='loginSubBtn w-50 mt-2 btn btn-outline-secondary btn-small' :to="{ name: 'login' }">
                <translate>Return to login</translate>
              </router-link>
            </div>
          </FormulateForm>
        </div>

        <div v-else style="width: 100%;" class="fI">
          <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper fI-slow" style="padding: 28px 0 29px 0;">
              <div style="width: 32%!important; margin: auto;">
                <div v-translate>
                  We have sent you a password reset email.
                </div>
                <div v-translate>
                  Please <b>follow the instructions</b> there.
                </div>
              </div>
            </div>
          <div class="logo_spacer_bottom"></div>
        </div>

      </div>

    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { AUTH__FORGOT_PASSWORD } from '@/store/actions.type'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

export default {
  name: 'ForgotPassword',
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter },
  data () {
    return {
      values: {
        email: (this.$route.query.email ? this.$route.query.email : null)
      },
      sentRequest: false,
      errors: {}
    }
  },
  created () {
    if (this.values && this.values.email) {
      this.forgotPassword()
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    handleErrors (err) {
      const status = parseInt(err.response.status)

      if (status === 400) {
        this.errors = { root: err.response.data.detail }
      } else if (status === 422) {
        const errorsObject = { email: [] }
        err.response.data.detail
          .map((err) => {
            errorsObject[err.loc[1]].push(err.msg)
          })
        this.errors = errorsObject
      }
    },
    forgotPassword () {
      this.$store
        .dispatch(AUTH__FORGOT_PASSWORD, {
          email: this.values.email
        })
        .then((response) => {
          this.sentRequest = true
        })
        .catch((error) => {
          this.handleErrors(error)
          this.sentRequest = false
        })
    }
  }
}
</script>

<style>
  @import '../assets/style/sessions.scss';
</style>
